<!-- Sidebar Header starts -->
<div class="sidebar-header" >
  <div class="logo clearfix">
    <div class="logo-text">
      <div class="logo-img">
        <img *ngIf="!this.config.layout.sidebar.collapsed" src="assets/img/farmagora/Logo-Orizzontale.svg" alt="Farmagorà logo" style="width: 132px; height: 48px"/>
        <img *ngIf="this.config.layout.sidebar.collapsed" src="assets/img/farmagora/Logo-Monogramma.svg" alt="Farmagorà logo" style="width: 24px; height: 32px"/>
      </div>
    </div>
    <!--<a class="nav-toggle d-none d-lg-none d-xl-block" id="sidebarToggle" href="javascript:;"
       (click)="toggleSidebar()">
      <i #toggleIcon [ngClass]="{
        'ft-toggle-left': config.layout.sidebar.collapsed,
        'ft-toggle-right': !config.layout.sidebar.collapsed
      }" class="toggle-icon"></i>
    </a>-->
    <a class="nav-close d-block d-lg-block d-xl-none" id="sidebarClose" href="javascript:" (click)="CloseSidebar()">
      <i class="ft-x"></i>
    </a>
  </div>
</div>
<!-- Sidebar Header Ends -->

<!-- Sidebar Content starts -->
<div class="sidebar-content">
  <div class="blue-border"></div>
  <div class="nav-container">
    <ul class="navigation">
      <!-- First level menu -->
      <li *ngFor="let menuItem of menuItems" [ngClass]="[menuItem.class]"
          [routerLinkActive]="menuItem.submenu.length != 0 ? '' : 'active'">
        <a [routerLink]="menuItem.class === '' ? [menuItem.path] : null"
           *ngIf="!menuItem.isExternalLink; else externalLinkBlock">
          <i [ngClass]="[menuItem.icon]"></i>
          <span class="menu-title">{{menuItem.title}}</span>
          <span *ngIf="menuItem.badge != '' " [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
        </a>
        <ng-template #externalLinkBlock>
          <a [href]="[menuItem.path]" target="_blank">
            <i [ngClass]="[menuItem.icon]"></i>
            <span class="menu-title">{{menuItem.title}}</span>
            <span *ngIf="menuItem.badge != '' " [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
          </a>
        </ng-template>
        <!-- Second level menu -->
        <ul class="menu-content" *ngIf="menuItem.submenu.length > 0">
          <li *ngFor="let menuSubItem of menuItem.submenu"
              [routerLinkActive]="menuSubItem.submenu.length > 0 ? '' : 'active'" [ngClass]="[menuSubItem.class]">
            <a [routerLink]="menuSubItem.submenu.length > 0 ? null : [menuSubItem.path]"
               *ngIf="!menuSubItem.isExternalLink; else externalSubLinkBlock">
              <i [ngClass]="[menuSubItem.icon]"></i>
              <span class="menu-title">{{menuSubItem.title}}</span>
              <span *ngIf="menuSubItem.badge != '' " [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
            </a>
            <ng-template #externalSubLinkBlock>
              <a [href]="[menuSubItem.path]">
                <i [ngClass]="[menuSubItem.icon]"></i>
                <span class="menu-title">{{menuSubItem.title}}</span>
                <span *ngIf="menuSubItem.badge != '' " [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
              </a>
            </ng-template>
            <!-- Third level menu -->
            <ul class="menu-content" *ngIf="menuSubItem.submenu.length > 0">
              <li *ngFor="let menuSubsubItem of menuSubItem.submenu" routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}"
                  [ngClass]="[menuSubsubItem.class]">
                <a [routerLink]="[menuSubsubItem.path]"
                   *ngIf="!menuSubsubItem.isExternalLink; else externalSubSubLinkBlock">
                  <i [ngClass]="[menuSubsubItem.icon]"></i>
                  <span class="menu-title">{{menuSubsubItem.title}}</span>
                  <span *ngIf="menuSubsubItem.badge != '' " [ngClass]="[menuSubsubItem.badgeClass]">{{menuSubsubItem.badge}}</span>
                </a>
                <ng-template #externalSubSubLinkBlock>
                  <a [href]="[menuSubsubItem.path]">
                    <i [ngClass]="[menuSubsubItem.icon]"></i>
                    <span class="menu-title">{{menuSubsubItem.title}}</span>
                    <span *ngIf="menuSubsubItem.badge != '' " [ngClass]="[menuSubsubItem.badgeClass]">{{menuSubsubItem.badge}}</span>
                  </a>
                </ng-template>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
