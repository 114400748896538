<nav class="navbar navbar-expand-md navbar-dark bg-faded" style="margin-left: 0">
  <div class="container-fluid">
    <div class="navbar-header">
      <div style="display: none !important;" class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"  data-toggle="collapse">
        <i class="ft-menu font-medium-3"></i>
      </div>
      <ul class="navbar-nav">
        <img alt="logo" src="assets/img/iila/LOGO-IALC-{{translate.currentLang}}.svg" height="70px">
          <!--<img alt="logo" src="assets/img/biancoiila.jpg" height="70px">-->
        </ul>
      </div>
      <div class="navbar-container">
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav">
              <li class="nav-item" style="margin: auto">
                  <i class="icon-language" style="font-size: 16px; color: #20448A"></i>
              </li>
            <li class="nav-item" style="margin: auto">
                <select #langSelect (change)="changeLang(langSelect.value)" style="background: transparent; border: none; color: #20448A; margin-right: 24px; font-size: 12px; cursor: pointer">
                    <option *ngFor="let lang of languageList" [value]="lang.value" [selected]="lang.value === translate.currentLang">{{lang.name}}</option>
                </select>
            </li>
           <li class="nav-item" style="margin: auto">
              <span class="nav-link"  *ngIf="!isLoginAsLogout()" style="font-size: 12px; line-height: 20px;">
               {{getNavbarUserString()}}
              </span>
             <a class="nav-link" *ngIf="isLoginAsLogout()" (click)="loginAs(0)">
               {{getNavbarUserString()}}
             </a>
           </li>
            <li class="nav-item" style="margin: auto">
              <a class="nav-link" (click)="logout()" title="Logout">
                <i class="icon-logout" style="font-size: 16px"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
