import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  private toggleSidebar = new Subject<boolean>(); // small screen
  private overlaySidebarToggle = new Subject<boolean>();
  private toggleNotiSidebar = new Subject<boolean>();
  private refMedia = new Subject<any>();
  private upFile = new Subject<any>();
  private upVideo = new Subject<any>();

  // Observable
  toggleSidebar$ = this.toggleSidebar.asObservable();
  overlaySidebarToggle$ = this.overlaySidebarToggle.asObservable();
  toggleNotiSidebar$ = this.toggleNotiSidebar.asObservable();
  refreshMedia$ = this.refMedia.asObservable();
  uploadFile$ = this.upFile.asObservable();
  uploadVideo$ = this.upVideo.asObservable();

  toggleSidebarSmallScreen(toggle: boolean) {
    this.toggleSidebar.next(toggle);
  }

  overlaySidebartoggle(toggle: boolean) {
    this.overlaySidebarToggle.next(toggle);
  }

  toggleNotificationSidebar(toggle: boolean) {
    this.toggleNotiSidebar.next(toggle);
  }

  refreshMediaLibrary(file: any){
    this.refMedia.next(file);
  }

  uploadFile(file: any){
    this.upFile.next(file);
  }

  uploadVideo(file: any){
    this.upVideo.next(file);
  }

}
