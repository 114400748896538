import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';

@Injectable()
export class MediaLibraryService {
  private resourceUrl = environment.apiUrl + 'media';

  constructor(private http: HttpClient) {
  }

  getAllMedia(mock?: boolean): Observable<any[]> {
    if (mock) {
      return of(this.getAllProjectsMock());
    }
    return this.http.get<any[]>(this.resourceUrl + '/list');
  }

  getAllMediaByType(idMediaType: number): Observable<any[]> {
    return this.http.get<any[]>(this.resourceUrl + '/list/' + idMediaType);
  }

  getMedia(id: number): Observable<any> {
    return this.http.get<any>(this.resourceUrl + '/' + id  );
  }

  getMediaHeader(id: number): Observable<any> {
    return this.http.get<any>(this.resourceUrl + '/' + id + '/header/');
  }

  uploadFile(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<any[]>(this.resourceUrl + '/upload/file', formData, {observe: 'response'});
  }

  changeFile(id: number, file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<any[]>(this.resourceUrl + '/' + id + '/upload/file', formData, {observe: 'response'});
  }

  uploadVideo(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<any[]>(this.resourceUrl + '/upload/video', formData, {observe: 'response'});
  }

  save(request: any): Observable<any> {
    return this.http.post<any>(this.resourceUrl, request);
  }

  delete(request: any): Observable<any> {
    return this.http.post<any>(this.resourceUrl + '/delete', request);
  }

  private getAllProjectsMock() {
    return [
      {
        id: 1,
        name: 'Project 1',
        content: 'The cage',
        complete: '120/150',
        date: '12/12/2012',
        action: ['delete']
      },
      {
        id: 1,
        name: 'Project 1',
        content: 'The cage',
        complete: '120/150',
        date: '12/12/2012',
        action: ['delete']
      },
      {
        id: 1,
        name: 'Project 1',
        content: 'The cage',
        complete: '120/150',
        date: '12/12/2012',
        action: ['delete']
      },
      {
        id: 1,
        name: 'Project 1',
        content: 'The cage',
        complete: '120/150',
        date: '12/12/2012',
        action: ['delete']
      },
      {
        name: 'Project 1',
        content: 'The cage',
        complete: '120/150',
        date: '12/12/2012',
        action: ['delete']
      },
      {
        id: 1,
        name: 'Project 1',
        content: 'The cage',
        complete: '120/150',
        date: '12/12/2012',
        action: ['delete']
      },
      {
        id: 1,
        name: 'Project 1',
        content: 'The cage',
        complete: '120/150',
        date: '12/12/2012',
        action: ['delete']
      },
      {
        id: 1,
        name: 'Project 1',
        content: 'The cage',
        complete: '120/150',
        date: '12/12/2012',
        action: ['delete']
      },
      {
        name: 'Project 1',
        content: 'The cage',
        complete: '120/150',
        date: '12/12/2012',
        action: ['delete']
      },
      {
        id: 1,
        name: 'Project 1',
        content: 'The cage',
        complete: '120/150',
        date: '12/12/2012',
        action: ['delete']
      },
      {
        id: 1,
        name: 'Project 1',
        content: 'The cage',
        complete: '120/150',
        date: '12/12/2012',
        action: ['delete']
      },
      {
        id: 1,
        name: 'Project 1',
        content: 'The cage',
        complete: '120/150',
        date: '12/12/2012',
        action: ['delete']
      },
      {
        id: 1,
        name: 'Project 1',
        content: 'The cage',
        complete: '120/150',
        date: '12/12/2012',
        action: ['delete']
      },
      {
        id: 1,
        name: 'Project 1',
        content: 'The cage',
        complete: '120/150',
        date: '12/12/2012',
        action: ['delete']
      },
      {
        id: 1,
        name: 'Project 1',
        content: 'The cage',
        complete: '120/150',
        date: '12/12/2012',
        action: ['delete']
      },
    ];
  }

}
