import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {ROUTES} from './sidebar-routes.config';
import * as _ from 'lodash';
import * as $ from 'jquery';
import {JhiEventManager} from 'ng-jhipster';
import {Principal} from '../../block/auth/principal.service';
import {Router} from '@angular/router';
import {SUPER_ADMIN} from '../../app.constants';
import {ConfigService} from '../../shared/services/config.service';
import {LayoutService} from '../../shared/services/layout.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {DOCUMENT} from '@angular/common';
import {document} from 'ngx-bootstrap/utils';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})

export class SidebarComponent implements OnInit {
  @ViewChild('toggleIcon') toggleIcon: ElementRef;
  public menuItems: any[];
  public config: any = {};
  account: any;
  protected innerWidth: any;
  perfectScrollbarEnable = true;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private principal: Principal,
    private eventManager: JhiEventManager,
    private configService: ConfigService,
    private router: Router,
    private layoutService: LayoutService,
    private deviceService: DeviceDetectorService

  ) {
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;
    this.isTouchDevice();
  }

  ngOnInit() {
    this.getAccount();
    this.registerAuthenticationSuccess();
    //$.getScript('./assets/js/app-sidebar.js');
  }

  private getAccount() {
    this.principal.identity().then((account) => {
      this.account = account;
      this.loadMenuItems();
    });
  }
  isTouchDevice() {

    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();

    if (isMobile || isTablet) {
      this.perfectScrollbarEnable = false;
    }
    else {
      this.perfectScrollbarEnable = true;
    }

  }
  registerAuthenticationSuccess() {
    this.eventManager.subscribe('authenticationSuccess', (message) => {
      this.principal.identity(true).then((account) => {
        this.account = account;
        this.loadMenuItems();
        this.router.navigate(['/academy']);
      });
    });
  }

  CloseSidebar() {
    this.layoutService.toggleSidebarSmallScreen(false);
  }

  toggleSidebar() {
    const conf = this.config;
    conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
    this.configService.applyTemplateConfigChange({ layout: conf.layout });

    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }


  fireRefreshEventOnWindow = function () {
    const evt = this.document.createEvent('HTMLEvents');
    evt.initEvent('resize', true, false);
    window.dispatchEvent(evt);
  };
  private loadMenuItems() {

    this.menuItems = ROUTES;
    /*if (this.account && this.account.loggedUser && this.account.loggedUser.role === SUPER_ADMIN) {
      const myItems = _.filter(ROUTES, (elem) => {
        return elem.isaac || (this.account.project && elem.project && this.account.projects.length > 0);
      });
      this.menuItems = myItems;
    }

    if (this.account && this.account.loggedUser && this.account.loggedUser.dealer) {
      const myItems = _.filter(ROUTES, (elem) => {
        return elem.dealer || (this.account.projects && elem.project && this.account.projects.length > 0);
      });
      this.menuItems = myItems;
    }

    if (this.account && this.account.loggedUser && this.account.loggedUser.trainer) {
      const myItems = _.filter(ROUTES, (elem) => {
        return elem.trainer || (this.account.projects && elem.project && this.account.projects.length > 0);
      });
      this.menuItems = myItems;
    }*/
    if (this.account && this.account.role) {
      const permission: string[] = [];
      if (this.account) {
        if (this.account.asIam) {
          permission[0] = this.account.asIam.role;
        } else {
          permission[0] = this.account.role;
        }
        this.menuItems = _.filter(ROUTES, (elem: any) => {
          return _.intersection(permission, elem.permission).length;
        });
      } else {
        this.menuItems = [];
      }
      /*
       if (!this.account.loggedAs || !this.account.loggedAs.permissions) {
         this.menuItems = myItems;
       } else {
         this.menuItems = _.filter(myItems, (elem: any) => {
           if (_.intersection(Array(this.account.comiteeType), elem.committee).length) {
             return _.intersection(this.account.loggedAs.permissions, elem.permission).length;
           } else {
             return 0;
           }
         });
       }*/
    }
  }
}
