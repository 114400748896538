import {Component, Inject, OnInit} from '@angular/core';
import {JhiEventManager} from 'ng-jhipster';
import {Router} from '@angular/router';
import {AuthServerProvider} from '../../block/auth/auth-jwt.service';
import {Principal} from '../../block/auth/principal.service';
import {AccountService} from '../../block/auth/account.service';
import {ModalGenericService} from '../../shared/modal-generic/modal-generic.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ADMIN, DELEGATION, SUPER_ADMIN} from '../../app.constants';
import {DOCUMENT} from "@angular/common";
import {ConfigService} from "../../shared/services/config.service";
import {TranslateService} from '@ngx-translate/core';
import {LocalStorageService} from 'ngx-webstorage';
import {CustomDatepickerI18n, I18n} from '../../shared/date-custom/ng-bootstrap-datepicker-i18n';
import {NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}]
})

export class NavbarComponent implements OnInit {
  bsModalRef: BsModalRef;
  account: any;
  elem;

  isFullscreen: any = false;

  selectedLanguage: number = 1;

  languageList : any[];

  public config: any = {};

  constructor(
    @Inject(DOCUMENT) private document: any,
    private authServerProvider: AuthServerProvider,
    private eventManager: JhiEventManager,
    private principal: Principal,
    private accountService: AccountService,
    private genericModalService: ModalGenericService,
    private router: Router,
    private configService: ConfigService,
    public translate: TranslateService,
    private localStorage: LocalStorageService,
    private _i18n: I18n,
  ) {
    this.config = this.configService.templateConf;
    if(localStorage.retrieve('language')){
      translate.setDefaultLang(localStorage.retrieve('language'));
      translate.use(localStorage.retrieve('language'));
    }else {
      translate.setDefaultLang('en');
      translate.use('en');
      localStorage.store("language","en");
    }
  }

  ngOnInit() {
    this.getAccount();
    this.registerAuthenticationSuccess();
    this.elem = this.document.documentElement;
    this.languageList = [
      { id: 1, name: 'English', value: 'en' },
      { id: 2, name: 'Espanõl', value: 'es' },
      { id: 3, name: 'Français', value: 'fr' },
      { id: 4, name: 'Italiano', value: 'it' },
      { id: 5, name: 'Português', value: 'pt' },
    ];
  }

  changeLang(langSelected){
    this.translate.use(langSelected);
    this.localStorage.store("language",langSelected);
    this._i18n.language = langSelected;
  }

  isLoginAsLogout() {
   return this.account &&  this.account.canLoginAs && this.account.asIam;
  }

  getNavbarUserString() {
    if (this.account) {
      if (this.account.role === SUPER_ADMIN && !this.isLoginAsLogout()) {
        return this.account.username;
      }
      if (this.account.role === SUPER_ADMIN && this.isLoginAsLogout()) {
        return this.account.username + ' (' + this.account.role + ')'
      }
      if (this.account.role === DELEGATION) {
        if(this.account.iam){
          return this.account.iam.delegationName;
        }
      }
    }
  }

  Fullscreen() {
    if(!this.isFullscreen){
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
        this.isFullscreen = true;
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
        this.isFullscreen = true;
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
        this.isFullscreen = true;
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
        this.isFullscreen = true;
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
        this.isFullscreen = false;
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
        this.isFullscreen = false;
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
        this.isFullscreen = false;
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
        this.isFullscreen = false;
      }
    }
  }

  toggleSidebar() {
    const conf = this.config;
    conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
    this.configService.applyTemplateConfigChange({ layout: conf.layout });

    /*setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);*/
  }

  private getAccount() {
    this.principal.identity().then((account) => {
      this.account = account;
    });
  }

  registerAuthenticationSuccess() {
    this.eventManager.subscribe('authenticationSuccess', (message) => {
      this.principal.identity(true).then((account) => {
        this.account = account;
      });
    });
  }

  logout() {
    this.authServerProvider.logout().subscribe(res => {});
    this.principal.authenticate(null);
    this.router.navigate(['/login']);
  }

  loginAs(id?: number) {
    this.authServerProvider.loginAs(id).subscribe(res => {
      this.eventManager.broadcast({
        name: 'authenticationSuccess',
        content: 'Sending Authentication Success'
      });
    });
  }
}
