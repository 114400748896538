import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';

@Injectable()
export class UserService {
  private resourceUrl = environment.apiUrl + 'user';

  constructor(private http: HttpClient) {
  }

  getAllUsers(mock?: boolean): Observable<any[]> {
    if (mock) {
      return of(this.getAllProjectsMock());
    }
    return this.http.get<any[]>(this.resourceUrl + '/list');
  }

  getUser(id: number): Observable<any> {
    return this.http.get<any>(this.resourceUrl + '/' + id);
  }

  getUserHeader(id: number): Observable<any> {
    return this.http.get<any>(this.resourceUrl + '/' + id +  '/header/');
  }

  createUser(request): Observable<any> {
    return this.http.post<any[]>( this.resourceUrl , request);
  }

  save(request: any): Observable<any> {
    return this.http.post<any>(this.resourceUrl, request);
  }

  deleteUser( id: number): Observable<any> {
    return this.http.delete<any>(this.resourceUrl + '/' + id, {});
  }

  changePassword(request: any): Observable<any> {
    return this.http.post<any>(this.resourceUrl + '/changePassword', request);
  }

  private getAllProjectsMock() {
    return [
      {
        id: 1,
        name: 'Project 1',
        type: 'The cage',
      },
  ]};
}
