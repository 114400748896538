<div class="modal-header">
  <h4 class="modal-title pull-left">{{options.title?options.title:'Confirm'}}</h4>
  <div class="close clickable" data-dismiss="modal" (click)="bsModalRef.hide()"></div>
</div>
<div class="modal-body" [innerHTML]="options.message">
</div>
<div class="modal-footer">
  <button class="btn btn-outline-danger" *ngIf="options.closeButton" (click)="bsModalRef.hide()">{{options.closeButton}}</button>
  <button *ngFor="let button of options.buttonsDanger" class="btn btn-outline-danger" (click)="clickedButton(button)">{{button}}</button>
  <button *ngFor="let button of options.buttons" class="btn btn-secondary" (click)="clickedButton(button)">{{button}}</button>
</div>
