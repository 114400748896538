import { Routes, RouterModule } from '@angular/router';

//  Route for content layout with sidebar, navbar and footer
// loadChildren: () => import('../../pages/content-pages/content-pages.module').then(m => m.ContentPagesModule)
export const FULL_ROUTES: Routes = [
  {
    path: 'delegations',
    loadChildren: () => import('../modules/delegation/delegation.module').then(m => m.DelegationModule)
  },
  {
    path: 'gestione-files',
    loadChildren: () => import('../modules/gestione-files/gestione-files.module').then(m => m.GestioneFilesModule)
  },
  {
    path: 'export-list',
    loadChildren: () => import('../modules/export-list/export-list.module').then(m => m.ExportListModule)
  },
];
