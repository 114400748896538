import { JhiAlertService } from 'ng-jhipster';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injector } from '@angular/core';
import { Observable } from 'rxjs';

import {AlertService} from '../alert/alert.service';
import {tap} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';

export class NotificationInterceptor implements HttpInterceptor {

  private jhiAlertService: JhiAlertService;
  private alertService: AlertService

  // tslint:disable-next-line: no-unused-variable
  constructor(private injector: Injector
  ) {
    setTimeout(() => this.jhiAlertService = injector.get(JhiAlertService));
    setTimeout(() => this.alertService = injector.get(AlertService));
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        //console.log("NOTIFY");
        //console.log(event);
        // if (event.body.message) {
        //   this.alertService.success(event.body.message, null, 'top right');
        // }
        const arr = event.headers.keys();
        let alert = null;
        let alertType = null;
        arr.forEach((entry) => {
          if (entry.endsWith('app-alert')) {
            alert = event.headers.get(entry);
          }
          if (entry.endsWith('app-alert-type')) {
            alertType = event.headers.get(entry);
          }
        });
        if (alert) {
          if (typeof alert === 'string') {
            if (alert !== 'The log has been saved') {
            if (this.alertService) {
              /*if (alertType === 'warning') {
                this.toastr.warning(alert, 'Warning');
              } else {
                this.toastr.success(alert, 'Success');

              }*/
              this.alertService.addAlert(
              this.jhiAlertService.addAlert(
                  {
                    type: alertType === 'warning' ? 'warning' : 'success',
                    msg: alert,
                    timeout: 5000,
                    toast: this.jhiAlertService.isToast(),
                    scoped: true,
                    position: 'top right'
                  },
                  this.alertService.getAlerts()
                )
              );
            }
            }
          }
        }
      }
    }, (err: any) => {}));
  }
}
