import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';

@Injectable()
export class PartecipantRoleService {
  private resourceUrl = environment.apiUrl + 'role';

  constructor(private http: HttpClient) {
  }

  getAllRole(): Observable<any[]> {
    return this.http.get<any[]>(this.resourceUrl + '/list');
  }

  getRole(id: number): Observable<any> {
    return this.http.get<any>(this.resourceUrl + '/' + id  );
  }

  getRoleHeader(id: number): Observable<any> {
    return this.http.get<any>(this.resourceUrl + '/' + id + '/header/');
  }

  save(request: any): Observable<any> {
    return this.http.post<any>(this.resourceUrl , request);
  }

  delete( id: number): Observable<any> {
    return this.http.delete<any>(this.resourceUrl + '/' + id, {});
  }

  loadImage(id: number, file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<any[]>(this.resourceUrl + '/' + id + '/photo', formData, {observe: 'response'});
  }
}
