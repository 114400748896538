<!--<div id="loadessr" [hidden]="fasle">
  <div id="loader"></div>
</div>-->
<app-alert-error></app-alert-error>
<router-outlet></router-outlet>

<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Attenzione!</h4>
  </div>
  <div class="modal-body">
    Il browser che stai utilizzando non è supportato
    si consiglia di utilizzare le ultime versioni di Chrome o Firefox.
  </div>
</ng-template>
