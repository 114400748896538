import {Routes, RouterModule} from '@angular/router';

// Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const CONTENT_ROUTES: Routes = [
  {
    path: 'login',
    //loadChildren: './modules/login/login.module#LoginModule'
     loadChildren: () => import('../modules/login/login.module').then(m => m.LoginModule)

  }
];
