import { Component } from '@angular/core';
import {IHeaderParams} from 'ag-grid-community';
import {IHeaderAngularComp} from 'ag-grid-angular';

@Component({
    selector: 'app-header-custom',
    template: '<span class="ag-header-cell-text" (click)="onSort($event)" [translate]>{{this.params.displayName}}</span>',
})
export class MyHeaderComponent implements IHeaderAngularComp {
    public params: any;

    agInit(params: IHeaderParams): void {
        this.params = params;
    }

    onSort(event){
        this.params.progressSort(event.shiftKey);
    }
}
